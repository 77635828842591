.frame {
  position: relative;
  /* width: 33%; */
  height: 65%;
  background-size: 70% 95%;
  color: white;
  text-align: center;
  margin-left: -3vh !important;
  text-align: center;
  transition: all 0.5s;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
}

.frameBtn {
  position: absolute;
  bottom: 0;
  left: 3vh;
  width: 90%;
  height: 50%;
  transition: all 0.5s;
  background-color: transparent;
  border: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.UserCoin-images {
  height: 8vh
}

.timer {
  width: 100vw;
  height: 10vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1vh;
  left: 0%;
  font-size: 1.2rem;
  color: white;
  transition: all 0.5s;
  background-image: linear-gradient(90deg, transparent, black, transparent);
}


.showingCard {
  width: 100vw;
  height: 10vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40vh;
  left: 0%;
  font-size: 1.2rem;
  color: white;
  transition: all 0.5s;
  background-image: linear-gradient(90deg, transparent, black, transparent);
}


.clock {
  width: 40px;
  height: 40px;
  transition: all 0.5s;
  animation: animClock 1s infinite;
  animation-direction: alternate-reverse;
}

.cards {
  margin-top: 18.5vh;
  padding-left: 6vw;
  padding-right: 6vw;
  /* display: flex !important;
  justify-content: center !important; */
  transition: all 0.5s;
}

.coins {
  display: grid;
  position: relative;
  width: 100%;
  height: 20vh;
  transition: all 0.5s;
}

.sumCoins {
  margin-top: 0vh;
  font-size: 0.7rem !important;
  transition: all 0.5s;
}

.mine {
  margin-top: -2.9vh;
  font-size: 0.7rem !important;
  transition: all 0.5s;
}

.ribbon {
  width: 100%;
  height: 140px;
  /* padding-top: 5px; */
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  transition: all 0.5s;
  top: 0;
  left: 0%;
}

.card1Width {
  width: 420px;

}

.ribbon p {
  font-size: 1rem !important;
  margin-top: -1rem !important;
  transition: all 0.5s;
  /* font-weight: bolder; */
  display: inline-block;
}

.starRibbon {
  position: absolute;
  width: 55%;
  height: 140px;
  /* padding-top: 5px; */
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: contain;
  top: -50px;
  transition: all 0.5s;
  left: 26%;
}

.ribbonText {
  font-size: 17px;
  margin-top: 1.1rem;
}

.musicBtn,
.historyIcon {
  width: 30px;
  height: 30px;
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  -webkit-filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
}

.cardMargin {
  margin-top: 0.5em;
  margin-left: 10px;
}

@keyframes animClock {
  0% {
    margin-top: 5px;
    width: 22px;
    height: 22px;
    transform: rotate(-20deg);
  }

  100% {
    margin-top: 5px;
    width: 28px;
    height: 28px;
    transform: rotate(20deg);
  }
}

@media screen and (max-width:1440px) {
  .starRibbon {
    background-size: contain;
    top: -19px;
    left: 23%;
  }
}

@media screen and (max-width: 1200px) {
  .frame {
    width: 35vw;
    height: 65vh;
    background-size: 89% 100%;
  }

  .sumCoins {
    margin-top: 10px;
    font-size: 1.4rem;
  }

  .mine {
    margin-top: 30px;
    font-size: 1.2rem;
  }

  .cards {
    margin-top: 130px;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .timer {
    width: 45vw;
    top: 6px;
    left: 27.5%;
  }

  .ribbon {
    top: -3px;
    left: 27px;
    width: 90%;
  }

  .musicBtn {
    width: 50px;
    height: 50px;
  }

  .historyIcon {
    width: 40px;
    height: 40px;
  }

  .starRibbon p {
    margin-top: 5.5rem
  }

  .starRibbon {
    top: -44px;
    left: 110px;
    width: 60%;
    height: 165px;
  }
}

@media screen and (max-width: 1040px) {
  .cards {
    margin: 19vh 0 0 0;
  }

  .mine {
    margin-top: -33px;
  }
}

@media screen and(max-width: 850px) {
  .frame {
    width: 20vw;
    height: 400px;
    background-size: 99% 99%;
  }

  .timer {
    width: 65vw;
    top: 135px;
    left: 17.5%;
  }

  .musicBtn {
    width: 45px;
    height: 45px;
  }
}

@media screen and(min-width: 700px) {
  .starRibbon {
    /* padding-top: 60px; */
    top: 70px;
  }
}

@media screen and(min-width: 600px) {
  .ribbon {
    width: 83%;
    top: 17px;
    left: 24px;
  }

  .ribbon p {
    font-size: 0.4rem;
  }

  .starRibbon {
    /* padding-top: 45px; */
    width: 83%;
    top: 17px;
    left: 24px;

  }
}


@media screen and (max-width:475px) {
  .cardMargin {
    margin-top: 24.5px;
  }
  .UserCoin-images {
    height: 7vh
  }
  .historyIcon {
    width: 29px;
    height: 29px;
  }

  .musicBtn {
    width: 35px;
    height: 35px;
  }

}

@media screen and (max-width:320px) {
  .cards {
    padding: 0 10px 0 10px;
  }

}